
import { defineComponent } from "vue";
import {
  ButtonComponent,
  TInputComponent,
  ApiService,
  Logger,
  NotificationsService,
} from "table";
import ApiAuthService from "@/services/ApiAuthService/ApiAuthService";
import { ApiHelper } from "table";
import { AuthenticationService } from "@/services/AuthenticationService";

export default defineComponent({
  name: "AuthenticationDemo",
  components: {
    TInputComponent,
    ButtonComponent,
  },
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
    };
  },
  methods: {
    onKeyDownInput(e: KeyboardEvent) {
      if (e.code === "Enter") {
        this.onAuth();
      }
    },
    async onAuth() {
      if (Object.values(this.form).some((x) => x.trim() === "")) {
        NotificationsService.send({
          type: "error",
          title: "Аутентификация",
          text: "Введите логин и пароль",
          duration: 5000,
        });
        return;
      }

      try {
        await ApiAuthService.login(this.form);
        await AuthenticationService.updateCurrentUser();
        ApiService.clearCache();
        NotificationsService.send({
          type: "success",
          title: "Аутентификация",
          text: "Вы вошли в систему",
          duration: 5000,
        });
        const redirect = this.$route.query.redirect as string | undefined;
        // чтобы очистить ошибки авторизации, которые могут быть закэшированы
        await this.$router.push(redirect || "/");
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при авторизации",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
        return;
      }
    },
  },
});
